<template>
  <v-container fill-height align-center justify-center>
    <h1>404</h1>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound404',
};
</script>
